document.documentElement.classList.add('js-enabled')

window.addEventListener('load', function () {
  // Typing effect on command line (vendor/typed.min.js)
  Typed.new('.js-command', {
    strings: ['hac^100k^400pack'],
    startDelay: 200,
    typeSpeed: 50,
    cursorChar: '',
    callback: function() {
      var outputEl = document.querySelector('.js-output')
      var typeCursor = document.querySelector('.typed-cursor')
      outputEl.classList.add('visible')
      typeCursor.classList.add('hidden')
    }
  })
})
